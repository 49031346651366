import * as React from 'react';
import { Route, Routes } from 'react-router';

import { Theme } from './components/assets/Theme';

import { useSiteQuery } from './graphql/hooks/useSiteQuery';

import { ModalContextProvider } from './components/features/modal/ModalContext';
import { ToastContextProvider } from './components/features/toast/ToastContext';
import { Toasts } from './components/features/toast';

import { ErrorBoundary } from './components/utils/ErrorBoundary';
import { SiteLoader } from './components/ui/loaders/SiteLoader';

import { features, hocs, pages, utils } from './register';

const { Ds, DsPreviewPublicRoot } = hocs;
const { Modal, Correction } = features;

const {
  DsPreview,
  DsPreviewPublic,
  DsCorrectionApproved,
  DsSellerMadeCorrection,
  DsCorrectionPrint,
  PreviewPublic,
  Estate,
  ProjectPricelist,
  NotFound,
  NoAccess,
  Approval,
  Approved,
  Error
} = pages;
const { Seo } = utils;

export const Site: React.FC = React.memo(() => {
  const { data, loading, error } = useSiteQuery();

  if (loading) return <SiteLoader />;

  if (error?.graphQLErrors[0]?.extensions?.code === 'NOT_FOUND') {
    return <NotFound />;
  }

  if (error?.message) {
    return (
      <Error
        message={error.message}
        rcid={error?.graphQLErrors[0]?.extensions?.rcid as string}
      />
    );
  }

  const { brandId, brandName, favicon, style } = data?.layout ?? {};

  return (
    <React.Suspense fallback={<SiteLoader />}>
      <ErrorBoundary>
        <Theme brandId={brandId} styles={style}>
          <Seo title={`Digital salgsoppgave - ${brandName}`}>
            {favicon ? <link rel="shortcut icon" href={favicon} /> : null}
          </Seo>
          <ToastContextProvider>
            <ModalContextProvider>
              <Routes>
                <Route path="/order/:estateId/:dsId/" element={<Ds />}>
                  <Route path="preview" element={<DsPreview />} />
                  <Route path="approval" element={<Approval />} />
                  <Route path="approved" element={<DsCorrectionApproved />} />
                  <Route
                    path="seller-made-correction"
                    element={<DsSellerMadeCorrection />}
                  />
                  <Route path="correction" element={<Correction />} />
                </Route>
                <Route path="/prisliste/:estateId" element={<ProjectPricelist />} />
                <Route
                  path="/order/:estateId/:dsId/preview/public"
                  element={<DsPreviewPublicRoot />}
                >
                  <Route index element={<DsPreviewPublic />} />
                </Route>
                <Route
                  path="/correction-print/:estateId/:version"
                  element={<DsCorrectionPrint />}
                />
                <Route path="/preview/:estateId" element={<PreviewPublic />} />
                <Route path="/approved/:estateId/:version" element={<Approved />} />
                <Route path="/estate/:estateId" element={<Estate />} />
                <Route path="/eiendom/:estateId" element={<Estate />} />
                <Route path="/estate/:urltag/:estateId" element={<Estate />} />
                <Route path="/eiendom/:urltag/:estateId" element={<Estate />} />
                <Route path="/error" element={<Error />} />
                <Route path="/no-access" element={<NoAccess />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Modal />
              <Toasts />
            </ModalContextProvider>
          </ToastContextProvider>
        </Theme>
      </ErrorBoundary>
    </React.Suspense>
  );
});
