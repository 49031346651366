import { createGlobalStyle } from 'styled-components';

export const EmvestFonts = createGlobalStyle`
    @font-face {
        font-family: 'Fann Grotesque';
        src: url('https://cdn.reeltime.no/emvest_assets/fonts/fann-grotesque-regular-pro.otf');
        font-weight: 400;
    }
    @font-face {
        font-family: 'Fann Grotesque';
        src: url('https://cdn.reeltime.no/emvest_assets/fonts/FannGrotesque-Medium.ttf');
        font-weight: 500;
    }

    @font-face {
        font-family: 'Fann Grotesque';
        src: url('https://cdn.reeltime.no/emvest_assets/fonts/fann-grotesque-semibold-pro.otf');
        font-weight: 600;
    }
    
    @font-face {
        font-family: 'Tobias';
        src: url('https://cdn.reeltime.no/emvest_assets/fonts/Tobias-Bold.otf');
        font-weight: 600;
    }

    :root {
        --heading-font-family: 'Tobias', Helvetica, sans-serif;
        --body-font-family: 'Fann Grotesque', Helvetica, sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: var(--heading-font-family) !important;
        letter-spacing: 0;
    }
    

    * {
        -webkit-font-smoothing: antialiased;
        font-family: var(--body-font-family); 
    }
`;
