import * as React from 'react';

import { BelevenFonts } from '../templates/beleven/assets/Fonts';
import { PrivatMeglerenFonts } from '../templates/privatmegleren/assets/Fonts';
import { ReleMeglerenFonts } from '../templates/rele/assets/Fonts';
import { WlePartnersFonts } from '../templates/partners/assets/WlePartnersFonts';
import { WleStavlundFonts } from '../templates/partners/assets/WleStavlundFonts';
import { WleTinholtFonts } from '../templates/partners/assets/WleTinholtFonts';
import { WleEiendomsmeglerneFonts } from '../templates/partners/assets/WleEiendomsmeglerneFonts';
import { InterFonts } from '../templates/partners/assets/InterFonts';
import { EmvestFonts } from '../templates/emvest/assets/Fonts';

export const Fonts: React.FC<{ brandId?: string | null }> = ({ brandId }) => {
  switch (brandId) {
    case 'privatmegleren':
      return <PrivatMeglerenFonts />;
    case 'beleven':
      return <BelevenFonts />;
    case 'rele':
      return <ReleMeglerenFonts />;
    case 'wle.partners':
    case 'wle':
    case 'nylander':
      return (
        <>
          <WlePartnersFonts />
          <InterFonts />
        </>
      );
    case 'wle.stavlund':
      return <WleStavlundFonts />;
    case 'wle.tinholt':
      return <WleTinholtFonts />;
    case 'wle.eiendomsmeglerne':
      return <WleEiendomsmeglerneFonts />;
    case 'emvest':
      return <EmvestFonts />;
    default:
      return null;
  }
};
